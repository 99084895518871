const formToEndpoint = {
    1: 'new',
    2: 'resale',
    3: 'other',
    4: 'rentable',
    5: 'other'
};

const init = _ => {
    bindEvents()
}

const bindEvents = _ => {
    document.addEventListener('click', handleClick)
}

const handleClick = async evt => {
    const btn = evt.target.closest('[data-search-btn]');
    if (!btn) return;
    const form = document.querySelector('[data-form-parent-container].is-active [data-form-container].is-active');
    const inputValues = [...form.querySelectorAll('input:checked, input[type=number]')]
        .map(i => [i.name, i.value])
        .filter(([name, value]) => value)

    const selectValues = [
        ...form.querySelectorAll("select[data-dropdown-value]"),
    ]
        .map((select) => {
            const selectedOptions = select.querySelectorAll('option[selected]');
            return selectedOptions.length
                ? [...selectedOptions].map((option) => option.value ? [
                    select.name,
                    option.value,
                ] : null)
                : null
        }

        )
        .flat()
        .filter(item => !!item);

    const params = [...inputValues, ...selectValues].sort()
        .map(([name, value]) => `${name}=${value}`).join('&');


    window.location.href = `/catalog/${formToEndpoint[+form.dataset.formContainer]}?${params}`;

}


export default {
    init
}
